let url

if (process.env.NODE_ENV === 'production') {
  url = process.env.REACT_APP_SERVER_URL
}
else {
  url = process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : "http://127.0.0.1:8000"
}

export default url
