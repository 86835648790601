import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BiSearch } from 'react-icons/bi'
import '../CSS/SearchBar.css'
import serverUrl from '../Utilities/ServerUrl'
import axios from 'axios'

const SearchBar = ({ placeholder, inputRef }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [searchKey, setSearchKey] = useState("");

  const handleFilter = (e) => {
    setSearchKey(e.target.value)
  }

  useEffect(() => {
    if (searchKey === "") {
      setFilteredData([])
      return
    }

    const delayDebounceFn = setTimeout(() => {
      const encodedSearchKey = encodeURIComponent(searchKey)
      axios.get(`${serverUrl}/data/products/?format=json&search=${encodedSearchKey}`)
      .then(res => {
        setFilteredData(res.data.results)
      })
    }, 1250)

    return () => clearTimeout(delayDebounceFn)
  }, [searchKey]);

  function clearInput() {
    inputRef.current.value = ""
    setSearchKey("")
  }

  return (
    <div className="search-container py-2">
      <div className="search-wrapper">
        <div className="search-icon">
          <BiSearch size={20}/>
        </div>
        <input
          id="searchKeyInputId"
          type="text"
          placeholder={placeholder}
          onChange={handleFilter}
          ref={inputRef}
          className="search-input"
        />
      </div>
      {filteredData.length !== 0 && (
        <div className="dataResult">
          {filteredData.slice(0, 15).map((value, key) => {
            return (
              <Link to={`/p/${value.id}/${value.slug}`} key={key} onClick={clearInput}>
                <p>{value.name}</p>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchBar