import React, { createContext, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import serverUrl from '../Utilities/ServerUrl'

const AuthContext = createContext({
  isLoggedIn: false,
  handleLogin: (username, password) => {},
  handleLogout: () => {},
  authToken: '',
  profile: {name: '', phone: '', address: '', isStaff: false, npwp: ''},
  error: null,
  authHeader: '',
  fetchProfileData: (authToken) => {},
});

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('authToken'));
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'))
  const [authHeader, setAuthHeader] = useState(`Token ${localStorage.getItem('authToken')}`)
  const [error, setError] = useState(null);
  const [profileData, setProfileData] = useState(JSON.parse(localStorage.getItem("profile")) || {
    name: '',
    phone: '',
    address: '',
    isStaff: false,
  });

  const navigate = useNavigate(); 

  const handleLogin = async (username, password) => {
    try {
      const response = await fetch(`${serverUrl}/auth/token/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const authToken = data.auth_token;

        // Store the auth token, typically in local storage
        localStorage.setItem('authToken', authToken);
        setAuthToken(authToken);
        setAuthHeader(`Token ${authToken}`)
        setIsLoggedIn(true);
        setError(null);

        // Fetch the profile data
        await fetchProfileData(authToken);

        // Add console log here
        console.log('Login successful. Profile data:', profileData);

        // Navigate to home page after login
        navigate('/'); 
      } else {
        const errorData = await response.json();
        setError(errorData.detail || 'Login failed. Please check your username and password.');
      }
    } catch (err) {
      console.error('Error logging in:', err);
      setError('An error occurred. Please try again.');
    }
  };

  const handleLogout = async () => {
    const authToken = localStorage.getItem('authToken');

    if (!authToken) {
      console.error('You are not logged in.');
      return;
    }

    try {
      const response = await fetch(`${serverUrl}/auth/token/logout/`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${authToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok || response.status === 401) {
        localStorage.removeItem('profile');
        localStorage.removeItem('authToken');
        setIsLoggedIn(false);
        navigate('/'); 
        console.log('Logout successful');
      } else {
        console.error('Logout failed.');
      }
    } catch (err) {
      console.error('Error logging out:', err);
    }
  };

  // Fetch profile data after login using the token
  const fetchProfileData = async (authToken) => {
    try {
      const response = await fetch(`${serverUrl}/data/customer/`, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${authToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const profile = await response.json();

        // Set profile data in state
        const data = {
          id: profile.id,
          name: profile.name,
          phone: profile.phone,
          address: profile.address,
          npwp: profile.npwp,
          isStaff: profile.is_staff,
        }
        setProfileData(data);
        localStorage.setItem("profile", JSON.stringify(data))
        console.log('Profile fetched successfully!');
      } else {
        console.error('Failed to fetch profile data. error aja');
      }
    } catch (err) {
      console.error('Error fetching profile:', err);
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, profile: profileData, authToken, authHeader, handleLogin, handleLogout, error, fetchProfileData }}>
      {children}
    </AuthContext.Provider>
  );
};


export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

