import axios from 'axios'
import { Spinner } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import "../CSS/invoice/invoice.css"
import { format } from "../Utilities/Currency";
import serverUrl from "../Utilities/ServerUrl"
import InvoiceNote from './InvoiceNote';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom'
import { createInvoicePages, createTable, createTotalTableFooter } from '../Services/InvoiceService';
import InvoiceHeader from './InvoiceHeader';
import { useAuth } from '../Hooks/authProvider';

function createSaleInvoiceTableHeader(pageIndex) {
  return <>
    <thead>
      <tr>
        <th className='checkbox-col'>{pageIndex}</th>
        <th className="text-center px-2">Qty</th>
        <th className='name-col'>
          <div className="name-text px-2">Nama Barang</div>
        </th>
        <th className="price-col text-center">Harga</th>
        <th className="text-center px-2">Disc</th>
        <th className="price-col text-center">Subtotal</th>
      </tr>
    </thead>
  </>
}

function createSaleDetailRow(orderItem) {
  let price = orderItem.price_per_unit
  let formattedPrice = <div>{format(orderItem.price_per_unit)}</div>
  let discount = ''

  if (orderItem.discount > 0 && orderItem.pcs >= orderItem.wholesale_minimum_quantity) {
    price = price - (price * (orderItem.discount / 100))
    const ttkey = `wholesale-tooltip-${orderItem.id}`

    discount = <>
      <OverlayTrigger
        key={ttkey}
        placement="top"
        overlay={
          <Tooltip id={`tooltip-${orderItem.id}`}>
            Minimum pembelian {orderItem.wholesale_minimum_quantity}: diskon {orderItem.discount}%<br />
            <span style={{ textDecoration: "line-through" }}>{orderItem.price_per_unit}</span> Rp {format(price)}
          </Tooltip>
        }
      >
        <div>{orderItem.discount}%</div>
      </OverlayTrigger>
    </>
  }

  return (
    <tr key={`invrow-${orderItem.id}`}>
      <td className='checkbox-col'>
        <div className='invoice-checkbox'></div>
      </td>
      <td className='text-center'>{orderItem.pcs}</td>
      <td className='name-col'>
        <div className="name-text">{orderItem.product.name}</div>
      </td>
      <td className='price-col'>
        <div className='d-flex justify-content-between'>
          <div>Rp</div>
          <div>{formattedPrice}</div>
        </div>
      </td>
      <td className='discount-col text-center'>{discount}</td>
      <td className='price-col text-end'>
        <div className='d-flex justify-content-between'>
          <div>Rp</div>
          <div>{format(orderItem.subtotal)}</div>
        </div>
      </td>
    </tr>
  )

}

const Invoice = () => {
  const { orderUUID } = useParams();
  const [orderData, setOrderData] = useState();
  const auth = useAuth()
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.get(`${serverUrl}/data/order/${orderUUID}/`, {
      headers: {
        Authorization: auth.authHeader,
      }
    }).then(res => {
      setOrderData(res.data);
      let grandTotal = 0
      for (let orderDetail of res.data.order_items) {
        const sub = orderDetail.pcs * (orderDetail.price_per_unit - (orderDetail.price_per_unit * orderDetail.discount/100))
        grandTotal += sub
      }
      setTotal(grandTotal)
      setIsLoading(false);
    })
  }, [orderUUID]);

  if (isLoading) {
    return (
      <Spinner className="spinner" animation="border" role="status" size="xl">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  const invoiceHeader = <InvoiceHeader key={`invh-${orderData.id}`} orderData={orderData} />

  const data = createInvoicePages(orderData.order_items, invoiceHeader, (pageIndex, page, isLastPage) => {
    const thead = createSaleInvoiceTableHeader(pageIndex + 1)
    const tableRows = []
    for (let orderDetail of page) {
      tableRows.push(createSaleDetailRow(orderDetail))
    }
    const tfoot = isLastPage ? createTotalTableFooter(total) : null
    return createTable(thead, tableRows, tfoot)
  })

  return (
    <>
      <div className="container px-0 invoice-actions-container d-print-none" style={{ 'maxWidth': '850px' }}>
        <Link className='btn action-button mx-2 d-print-none' to="t">Thermal</Link>
        <button className='btn action-button mx-2 d-print-none' onClick={window.print}>Print</button>
      </div>

      {Object.keys(data.pages).map((pageNumber, index) => {
        return data.pages[pageNumber]
      })}

      <InvoiceNote total={total} orderData={orderData} />
    </>
  )
}

export default Invoice
